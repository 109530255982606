<template>
  <v-container fluid class="py-6">
    <v-row>
      <v-col lg="4" md="6" cols="12">
        <v-card class="card-shadow border-radius-xl h-100">
          <div class="px-4 pt-4">
            <h6 class="mb-0 text-h6 text-typo font-weight-bold">
              {{ 'Profile Information'|trans }}
            </h6>
          </div>
          <div class="px-4 py-4">
            <v-list v-if="profile">
              <v-list-item :ripple="false" class="px-0 border-radius-sm">
                <v-list-item-content class="py-0">
                  <div class="ms-4 text-body text-sm">
                    <strong class="text-dark">{{ 'Full Name'|trans }}:</strong>
                    &nbsp; {{ profile.name }}
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :ripple="false" class="px-0 border-radius-sm">
                <v-list-item-content class="py-0">
                  <div class="ms-4 text-body text-sm">
                    <strong class="text-dark">{{ 'Email'|trans }}:</strong>
                    &nbsp; {{ profile.email }}
                  </div>
                </v-list-item-content>
              </v-list-item>

              <v-list-item :ripple="false" class="px-0 border-radius-sm">
                <v-list-item-content class="py-0">
                  <div class="ms-4 text-body text-sm">
                    <strong class="text-dark">{{ 'Spoken languages'|trans }}:</strong>
                    &nbsp;
                    {{
                      profile.spokenLanguages
                        .map(function (lang) {
                          return spokenLanguages(lang);
                        })
                        .join(",")
                    }}
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-card>
      </v-col>
      <v-col lg="4" md="6" cols="12">
        <password-change v-if="profile" :profile="profile"></password-change>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="card-shadow border-radius-xl">
          <div class="px-4 pt-4">
            <h6 class="mb-1 text-typo text-h6 font-weight-bold">{{ 'Activity'|trans }}</h6>
            <p class="text-sm text-body">{{ 'Requests you have submitted'|trans }}</p>
          </div>
          <div class="px-4 py-4">
            <table-requests></table-requests>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TableRequests from "@/views/Frontoffice/Profile/Widgets/TableRequests";
import PasswordChange from "@/views/Backoffice/Profile/Widgets/PasswordChange";
export default {
  name: "Profile-Overview",
  components: { PasswordChange, TableRequests },
  data: function () {
    return {};
  },
  methods: {
    spokenLanguages: function (val) {
      return this.$options.filters.spokenLanguage(val);
    },
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
};
</script>
