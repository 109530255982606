<template>
  <div>
    <v-card class="card-shadow border-radius-xl mt-6">
      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :items="requests"
          :search="search"
          class="table"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat height="80">
              <v-row>
                <v-col cols="4" md="5">
                  <v-text-field
                    hide-details
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="search"
                    :placeholder="'Search'|trans"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fas fa-search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="3" md="2">
                  <v-select
                    :items="statusList"
                    item-value="id"
                    item-text="name"
                    v-model="filter.status"
                    outlined
                    dense
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                    "
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>

          <template v-slot:item.controls="{ item }">
            <div class="d-flex align-center">
              <span class="my-2 text-xs text-body font-weight-bold">
                <router-link
                  tag="span"
                  style="cursor: pointer"
                  :to="{
                    name: 'frontoffice-request-details',
                    params: { guid: item.guid },
                  }"
                  >{{ 'View details'|trans }}</router-link
                >
              </span>
            </div>
          </template>

          <template v-slot:item.status="{ item }">
            <div class="d-flex align-center">
              <span class="text-body text-xs">{{
                item.status | helpRequestStatus
              }}</span>
            </div>
          </template>

          <template v-slot:item.requester.name="{ item }">
            <div class="d-flex align-center">
              <span class="text-body text-xs"
                >{{ item.requester.name }} ({{ item.requester.email }})</span
              >
            </div>
          </template>
          <template v-slot:item.reviewer.name="{ item }">
            <div class="d-flex align-center">
              <span class="text-body text-xs" v-if="item.reviewer"
                >{{ item.reviewer.name }} ({{ item.reviewer.email }})</span
              >
            </div>
          </template>
          <template v-slot:item.assignee.name="{ item }">
            <div class="d-flex align-center">
              <span class="text-body text-xs" v-if="item.assignee"
                >{{ item.assignee.name }} ({{ item.assignee.email }})</span
              >
            </div>
          </template>
          <template v-slot:item.resolver.name="{ item }">
            <div class="d-flex align-center">
              <span class="text-body text-xs" v-if="item.resolver"
                >{{ item.resolver.name }} ({{ item.resolver.email }})</span
              >
            </div>
          </template>

          <template v-slot:item.servicesList="{ item }">
            <span class="my-2 text-xs text-body font-weight-bold">{{
              item.servicesList.join(",")
            }}</span>
          </template>

          <template v-slot:item.createdAt="{ item }">
            <span class="my-2 text-xs text-body font-weight-bold">{{
              item.createdAt
            }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "table-requests",
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      requests: [],
      search: "",
      filter: {
        status: null,
      },

    };
  },
  mounted() {
    this.getRequestsList();
  },
  methods: {
    getRequestsList() {
      fetch(process.env.VUE_APP_API_URL + "/help-requests/requester-list", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": this.authToken,
          "Accept-Language": this.$store.getters.getLocale,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.type !== "success") {
            this.localAlert = {
              type: "error",
              color: "red",
              message: data.message ? data.message : this.$translator.trans("Unable to fetch list!"),
            };

            return false;
          }

          this.requests = data.helpRequests;

          this.requests.map(function (request) {
            request.servicesList = request.services.map(function (service) {
              return service.name;
            });
          });
        })
        .catch((error) => {
          console.error("Error:", error);

          this.localAlert = {
            type: "error",
            color: "red",
            message: error.message ? error.message : this.$translator.trans("Unable to fetch list!"),
          };
        });
    },
  },
  watch: {},

  computed: {
    authToken() {
      return this.$store.getters.getAuthToken;
    },
    statusList(){
      return [
        {
          id: null,
          name: this.$translator.trans("Any status"),
        },
        {
          id: 0,
          name: this.$translator.trans("Pending"),
        },
        {
          id: 10,
          name: this.$translator.trans("In review"),
        },
        {
          id: 20,
          name: this.$translator.trans("Assigned"),
        },
        {
          id: 30,
          name: this.$translator.trans("In progress"),
        },
        {
          id: 40,
          name: this.$translator.trans("Solved"),
        },
        {
          id: 50,
          name: this.$translator.trans("Rejected"),
        },
      ];
    },
    headers(){

      return [
        {
          text: this.$translator.trans("Actions"),
          align: "start",
          sortable: false,
          value: "controls",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },

        {
          text: this.$translator.trans("Status"),
          value: "status",
          class: "text-secondary font-weight-bolder opacity-7",
          filter: (value) => {
            if (null === this.filter.status) {
              return true;
            }

            return value === this.filter.status;
          },
        },
        {
          text: this.$translator.trans("Requester"),
          value: "requester.name",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: this.$translator.trans("Reviewer"),
          value: "reviewer.name",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: this.$translator.trans("Assignee"),
          value: "assignee.name",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: this.$translator.trans("Resolver"),
          value: "resolver.name",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: this.$translator.trans("Services"),
          value: "servicesList",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: this.$translator.trans("Created at"),
          value: "createdAt",
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ];
    }
  },
};
</script>
