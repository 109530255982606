var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"card-shadow border-radius-xl mt-6"},[_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.requests,"search":_vm.search,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"mobile-breakpoint":"0"},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","height":"80"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4","md":"5"}},[_c('v-text-field',{staticClass:"\n                    input-style\n                    font-size-input\n                    text-light-input\n                    placeholder-light\n                    input-icon\n                  ",attrs:{"hide-details":"","dense":"","flat":"","filled":"","solo":"","height":"43","placeholder":_vm._f("trans")('Search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('template',{slot:"prepend-inner"},[_c('v-icon',{attrs:{"color":"#adb5bd","size":".875rem"}},[_vm._v("fas fa-search")])],1)],2)],1),_c('v-col',{attrs:{"cols":"3","md":"2"}},[_c('v-select',{staticClass:"\n                    input-style\n                    font-size-input\n                    text-light-input\n                    placeholder-light\n                  ",attrs:{"items":_vm.statusList,"item-value":"id","item-text":"name","outlined":"","dense":""},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}})],1)],1)],1)]},proxy:true},{key:"item.controls",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"my-2 text-xs text-body font-weight-bold"},[_c('router-link',{staticStyle:{"cursor":"pointer"},attrs:{"tag":"span","to":{
                  name: 'frontoffice-request-details',
                  params: { guid: item.guid },
                }}},[_vm._v(_vm._s(_vm._f("trans")('View details')))])],1)])]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-body text-xs"},[_vm._v(_vm._s(_vm._f("helpRequestStatus")(item.status)))])])]}},{key:"item.requester.name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-body text-xs"},[_vm._v(_vm._s(item.requester.name)+" ("+_vm._s(item.requester.email)+")")])])]}},{key:"item.reviewer.name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.reviewer)?_c('span',{staticClass:"text-body text-xs"},[_vm._v(_vm._s(item.reviewer.name)+" ("+_vm._s(item.reviewer.email)+")")]):_vm._e()])]}},{key:"item.assignee.name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.assignee)?_c('span',{staticClass:"text-body text-xs"},[_vm._v(_vm._s(item.assignee.name)+" ("+_vm._s(item.assignee.email)+")")]):_vm._e()])]}},{key:"item.resolver.name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.resolver)?_c('span',{staticClass:"text-body text-xs"},[_vm._v(_vm._s(item.resolver.name)+" ("+_vm._s(item.resolver.email)+")")]):_vm._e()])]}},{key:"item.servicesList",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"my-2 text-xs text-body font-weight-bold"},[_vm._v(_vm._s(item.servicesList.join(",")))])]}},{key:"item.createdAt",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"my-2 text-xs text-body font-weight-bold"},[_vm._v(_vm._s(item.createdAt))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }