<template>
  <v-card class="card-shadow border-radius-xl h-100">
    <div class="px-4 pt-4">
      <h6 class="mb-0 text-h6 text-typo font-weight-bold">{{ 'Account security'|trans }}</h6>
    </div>
    <div class="px-4 py-4">
      <strong class="text-dark">{{ 'Password change'|trans }}:</strong>
      &nbsp;
      <v-row v-if="localAlert">
        <v-col cols="12">
          <v-alert :type="localAlert.type" :color="localAlert.color">
            {{ localAlert.message }}
          </v-alert>
        </v-col>
      </v-row>

      <v-text-field
        v-model="currentPassword"
        type="password"
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        :placeholder="'Current password'|trans"
        class="
          input-style
          font-size-input
          border border-radius-md
          placeholder-lighter
          text-color-light
          mb-4
        "
      ></v-text-field>
      <v-text-field
        v-model="newPassword"
        type="password"
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        :placeholder="'New password'|trans"
        class="
          input-style
          font-size-input
          border border-radius-md
          placeholder-lighter
          text-color-light
          mb-4
        "
      ></v-text-field>
      <v-text-field
        v-model="newPasswordRepeat"
        type="password"
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        :placeholder="'Repeat new password'|trans"
        class="
          input-style
          font-size-input
          border border-radius-md
          placeholder-lighter
          text-color-light
          mb-4
        "
      ></v-text-field>

      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        class="
          font-weight-bold
          text-uppercase
          btn-default
          bg-gradient-default
          py-2
          px-6
          me-2
          w-100
          text-white
        "
        color="#5e72e4"
        small
        @click="handleSubmit"
        :loading="isLoading"
        >{{ 'Change password'|trans }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "PasswordChange",
  props: {
    profile: {
      type: Object,
    },
  },
  data() {
    return {
      localAlert: null,
      isLoading: false,
      currentPassword: null,
      newPassword: null,
      newPasswordRepeat: null,
    };
  },
  computed: {
    authToken() {
      return this.$store.getters.getAuthToken;
    },
  },
  methods: {
    handleSubmit() {
      this.localAlert = null;

      if (
        !this.currentPassword ||
        !this.newPassword ||
        !this.newPasswordRepeat
      ) {
        this.localAlert = {
          type: "error",
          color: "red",
          message: this.$translator.trans("Fill in all fields!"),
        };
        this.isLoading = false;
        return false;
      }
      if (this.newPassword !== this.newPasswordRepeat) {
        this.localAlert = {
          type: "error",
          color: "red",
          message: this.$translator.trans("New password must match the repetead password!"),
        };
        this.isLoading = false;
        return false;
      }

      this.isLoading = true;

      let data = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
      };

      fetch(process.env.VUE_APP_API_URL + "/profile/password-change", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": this.authToken,
          "Accept-Language": this.$store.getters.getLocale,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.type !== "success") {
            this.localAlert = {
              type: "error",
              color: "red",
              message: data.message
                ? data.message
                : this.$translator.trans("Unable to change password!"),
            };

            this.isLoading = false;

            return false;
          }

          this.localAlert = {
            type: "success",
            color: "green",
            message: data.message
              ? data.message
              : this.$translator.trans("Password changed successfully!"),
          };

          this.isLoading = false;
        })
        .catch((error) => {
          console.error("Error:", error);

          this.localAlert = {
            type: "error",
            color: "red",
            message: error.message
              ? error.message
              : this.$translator.trans("Unable to change password!"),
          };

          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
